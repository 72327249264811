/* You can add global styles to this file, and also import other style files */
body, html, #root {width: 100%; height: 100%; margin: 0; padding: 0}
#vin-crm {
    display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;
}

@import "~antd/lib/menu/style/index.css";

.ant-menu-submenu-popup::before {
  content: none !important;
}
.clear {
  clear: both;
}
#root {
  height: 100%;
  #CarDashboard-CX {
    height: 100%;
    .ant-layout {
      height: 100%;
      .ant-layout-content {
        position: relative;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        #cardashboardframeContainer {
          background-color: #dddddd;
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          left: 0;
          iframe {
            border: none;
            height: 100%;
          }
          .cx-loadingIndicator {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(5, 5, 5, 0.5);
            & > div {
              align-items: center;
              height: 100%;
            }
          }
        }
      }
    }

    .Toastify__toast-container {
      width: auto;
    }
  }
}


// this file is necessary because ant sub menus are appended to the root dom
.ant-menu-submenu.ant-menu-submenu-popup {
  transition: none;
}

.ant-menu-submenu-popup > .ant-menu,
.ant-menu.ant-menu-sub.ant-menu-vertical {
  background-color: #2053ab;

  .ant-menu-item {
    &:active,
    &:hover {
      background-color: #173a78;
    }

    &.ant-menu-item-selected {
      background-color: #173a78;
    }

    .btn-link,
    .btn-primary {
      margin: 0;
      padding: 0;
      color: #fff;

      &:hover,
      &:focus,
      &:active,
      &:focus-visible {
        color: #fff;
        background: inherit;
        border-color: transparent;
        box-shadow: none;
      }

      a,
      a:hover {
        color: #fff;
      }
    }
  }

  .ant-menu-submenu {
    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      background-color: #173a78;
    }

    .ant-menu-submenu-title {
      color: #fff;

      &:active,
      &:hover {
        background-color: #173a78;
      }

      .ant-menu-submenu-expand-icon,
      .ant-menu-submenu-expand-icon:hover {
        color: #fff;
      }
    }
  }
}

.ant-menu-submenu-popup {
  .ant-menu-item {

    .btn {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      text-align: left;

      &.btn-primary {
        background-color: transparent;
        border-color: transparent;
        transition: none;
      }
    }
  }
}

/**
This is a bad solve for a complex problem. Ant design does a calculation based on the current length
of the submenu to determine the absolute coordinates for the popup menu. The popup menu itself is at the root,
so its very hard to set the coordinates relative to the nav item. I have overwritten the height of the menu to always
be smaller than the view height, but this is only true when the header is less than 200px. If the header extends beyond that
we will see buggy behavior again. Really, we need access to kill the calc function via props from antd...
 */

.ant-menu-submenu-popup {
  > ul.ant-menu {
    max-height: calc(100vh - 200px) !important;
  }
}

.row.line {
  border-top: 1px solid #cacfd9;
  padding-top: 10px;
}

div.center {
  text-align: center;
  vertical-align: center;
}
